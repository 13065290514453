import React from "react";
import PropTypes from "prop-types";
import InputRange from "../../common/InputRange";
import Button from "../../common/Button";

const EstimateFormStep2 = ({ t, values, updateValue, onSubmit, budgetError, setBack }) => (
  <>
    <form onSubmit={onSubmit} id="EstimateFormStep2">
      <InputRange name="budget" value={values.budget} onChange={updateValue} min={500} max={100000} />

      <div className="Estimate__form__estimateValue">
        <label className="Input">
          <input type="number" name="budget" value={values.budget} onChange={updateValue} />
        </label>
        <p>PLN</p>
      </div>

      {!!budgetError && <p className="error">{budgetError}</p>}
    </form>

    <div className="Estimate__form__btns">
      <Button onClick={setBack}>{t.back}</Button>

      <Button form="EstimateFormStep2">{t.next}</Button>
    </div>
  </>
);

EstimateFormStep2.propTypes = {
  t: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  budgetError: PropTypes.string,
  setBack: PropTypes.func.isRequired
};

export default EstimateFormStep2;
