import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../../common/Input";
import Textarea from "../../common/Textarea";
import Button from "../../common/Button";
import RodoInfo from "../../common/RodoInfo";
import FormInfoPopup from "../../common/FormInfoPopup";
import { getErrorMessageByFieldName } from "../../../utils/common";

const EstimateFormStep3 = ({ lang, t, values, updateValue, onSubmit, errors, captchaRef, verifyCallback, infoPopup, setInfoPopup, setBack }) => {
  return (
    <>
      <form onSubmit={onSubmit} id="EstimateFormStep3">
        <Input
          name="name"
          type="text"
          value={values.name}
          onChange={updateValue}
          labelText={t.name.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "name")}
        />
        <div className="form-column">
          <Input
            name="email"
            type="email"
            value={values.email}
            onChange={updateValue}
            labelText={t.email.placeholder}
            maxLength={255}
            errorMessage={getErrorMessageByFieldName(errors, "email")}
          />
          <Input
            name="phone"
            type="text"
            value={values.phone}
            onChange={updateValue}
            labelText={t.phone.placeholder}
            maxLength={255}
            errorMessage={getErrorMessageByFieldName(errors, "phone")}
          />
        </div>
        <Textarea
          name="message"
          value={values.message}
          onChange={updateValue}
          rows={10}
          labelText={t.message.placeholder}
          maxLength={500}
          errorMessage={getErrorMessageByFieldName(errors, "message")}
        />

        <div className="ReCAPTCHA">
          <ReCAPTCHA ref={captchaRef} sitekey={process.env.RECAPTCHA_PUBLIC_KEY} onChange={verifyCallback} hl={lang} />
          {getErrorMessageByFieldName(errors, "captcha") && <p className="error">{getErrorMessageByFieldName(errors, "captcha")}</p>}
        </div>
      </form>

      <div className="Estimate__form__btns">
        <Button onClick={setBack}>{t.back}</Button>

        <Button form="EstimateFormStep3">{t.submit}</Button>
      </div>

      <RodoInfo t={t.rodoInfo} />

      {infoPopup && <FormInfoPopup info={infoPopup} close={() => setInfoPopup("")} />}
    </>
  );
};

EstimateFormStep3.propTypes = {
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({ fieldName: PropTypes.string, errorMessage: PropTypes.string })),
  captchaRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  verifyCallback: PropTypes.func.isRequired,
  infoPopup: PropTypes.string.isRequired,
  setInfoPopup: PropTypes.func.isRequired,
  setBack: PropTypes.func.isRequired
};

export default EstimateFormStep3;
