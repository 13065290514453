import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../common/Checkbox";
import Button from "../../common/Button";
import { findItemFromArray } from "../../../utils/common";

const EstimateFormStep1 = ({ t, values, updateServices, onSubmit, servicesError }) => {
  return (
    <form onSubmit={onSubmit}>
      {t.services.list.map((elem, i) => (
        <Checkbox key={i} name={elem.name} checked={!!findItemFromArray(values.services, elem.name)} onChange={updateServices} labelText={elem.label} />
      ))}

      {!!servicesError && <p className="error">{servicesError}</p>}

      <div className="Estimate__form__btns">
        <Button>{t.next}</Button>
      </div>
    </form>
  );
};

EstimateFormStep1.propTypes = {
  t: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateServices: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  servicesError: PropTypes.string.isRequired
};

export default EstimateFormStep1;
