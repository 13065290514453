import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ step, steps }) => (
  <div className="ProgressBar">
    <div className={`ProgressBar__bar ProgressBar__bar--step${step}`}></div>

    {steps.map((elem, index) => (
      <div key={index} className={`ProgressBar__step ${step >= index + 1 ? "ProgressBar__step--active" : ""}`}>
        <div className="ProgressBar__step__point"></div>
        <p>{elem}</p>
      </div>
    ))}
  </div>
);

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ProgressBar;
