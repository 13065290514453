import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import EstimateFormStep1 from "./EstimateFormStep1";
import EstimateFormStep2 from "./EstimateFormStep2";
import EstimateFormStep3 from "./EstimateFormStep3";
import useForm from "./useForm";

const EstimateForm = ({ lang, t, step, setStep }) => {
  const captchaRef = useRef();

  const [infoPopup, setInfoPopup] = useState("");
  const [values, updateValue, updateServices, submitHandler, verifyCallback, errors, servicesError, budgetError] = useForm(
    {
      name: "",
      email: "",
      phone: "",
      message: "",
      captcha: null,
      services: [],
      budget: 5000
    },
    captchaRef,
    setInfoPopup,
    t,
    step,
    setStep
  );

  const getForm = () => {
    switch (step) {
      case 2:
        return (
          <EstimateFormStep2
            t={t}
            values={values}
            updateValue={updateValue}
            onSubmit={submitHandler}
            budgetError={budgetError}
            setBack={() => setStep(step - 1)}
          />
        );
      case 3:
        return (
          <EstimateFormStep3
            lang={lang}
            t={t}
            values={values}
            updateValue={updateValue}
            onSubmit={submitHandler}
            errors={errors}
            captchaRef={captchaRef}
            verifyCallback={verifyCallback}
            infoPopup={infoPopup}
            setInfoPopup={setInfoPopup}
            setBack={() => setStep(step - 1)}
          />
        );
      default:
        return <EstimateFormStep1 t={t} values={values} updateServices={updateServices} onSubmit={submitHandler} servicesError={servicesError} />;
    }
  };

  return <div className="Estimate__form">{getForm()}</div>;
};

EstimateForm.propTypes = {
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};

export default EstimateForm;
