import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import ProgressBar from "../common/ProgressBar";
import EstimateForm from "./EstimateForm";
import { getShareImage } from "../../utils/getShareImage";

const Estimate = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const [step, setStep] = useState(1);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Estimate">
        <div className="container Estimate__container">
          <div className="Estimate__left">
            <ProgressBar step={step} steps={t.progressBar} />

            <EstimateForm lang={lang} t={t.form} step={step} setStep={setStep} />
          </div>

          <div className="Estimate__info">
            <h4 className="small-title Estimate__info__description">{t.info.description}</h4>
            <p>{t.info.needNDA}</p>
            <p>
              {t.info.writeToUs} <a href={`mailto:${t.info.email}`}>{t.info.email}</a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Estimate.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Estimate;
