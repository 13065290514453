import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ labelText, errorMessage, ...attributes }) => (
  <div className="Checkbox">
    <label className={`Checkbox__label ${errorMessage ? "Checkbox__error" : ""}`}>
      {labelText}
      <input type="checkbox" {...attributes} />
      <span className="checkmark"></span>
    </label>
    {!!errorMessage && <p className="error">{errorMessage}</p>}
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

export default Checkbox;
