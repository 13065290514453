import { useState } from "react";
import FormService from "../../../services/FormService";
import { isName, isEmail, isPhone } from "../../../utils/validation";
import { removeItemFromArray } from "../../../utils/common";

const useForm = (initial, captchaRef, setInfoPopup, t, step, setStep) => {
  const [values, setValues] = useState(initial);
  const [errors, setErrors] = useState([]);
  const [servicesError, setServicesError] = useState("");
  const [budgetError, setBudgetError] = useState("");

  const updateValue = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const updateServices = ({ target }) => {
    const { name, checked } = target;
    let services = [];

    if (checked) services = [...values.services, name];
    else services = removeItemFromArray(values.services, name);

    setValues({ ...values, services });
  };

  const resetCaptcha = () => {
    setValues({ ...values, captcha: null });
    captchaRef.current.reset();
  };

  const verifyCallback = response => {
    setValues({ ...values, captcha: response });
  };

  const invalidBudget = budget => {
    return budget < 500 || budget > 1000000;
  };

  const validate = values => {
    const errorsList = [];

    if (!values.name) errorsList.push({ fieldName: "name", errorMessage: t.name.errors[0] });
    else if (!isName(values.name)) errorsList.push({ fieldName: "name", errorMessage: t.name.errors[1] });
    if (!values.email) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[0] });
    else if (!isEmail(values.email)) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[1] });
    if (!values.phone) errorsList.push({ fieldName: "phone", errorMessage: t.phone.errors[0] });
    else if (!isPhone(values.phone)) errorsList.push({ fieldName: "phone", errorMessage: t.phone.errors[1] });
    if (!values.message) errorsList.push({ fieldName: "message", errorMessage: t.message.errors[0] });
    if (!captchaRef.current.getValue()) errorsList.push({ fieldName: "captcha", errorMessage: t.captcha.errors[0] });

    return errorsList;
  };

  const submitHandler = async e => {
    e.preventDefault();

    if (step === 1) {
      if (!!values.services.length) {
        if (!!servicesError) setServicesError("");
        return setStep(2);
      }

      return setServicesError(t.services.errors[0]);
    } else if (step === 2) {
      const invalidBdg = invalidBudget(parseInt(values.budget));
      if (!invalidBdg) {
        if (!!budgetError) setBudgetError("");
        return setStep(3);
      }

      return setBudgetError(t.budget.errors[0]);
    }

    if (validate(values).length > 0) {
      resetCaptcha();
      return setErrors(validate(values));
    }
    if (errors.length > 0) setErrors([]);

    try {
      const data = { ...values, budget: parseInt(values.budget) };

      const response = await FormService.sendEstimateForm({ currentData: data });
      if (!!response.success) setInfoPopup(t.success);
      else throw response;

      resetCaptcha();
      setValues(initial);
    } catch (err) {
      setInfoPopup(t.error);
    }
  };

  return [values, updateValue, updateServices, submitHandler, verifyCallback, errors, servicesError, budgetError];
};

export default useForm;
